import { useState, useEffect } from "react"
import Slider from "react-slick"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import "slick-carousel/slick/slick.scss"
import "slick-carousel/slick/slick-theme.scss"
import ProductImage from "./ProductImage"
import classNames from "classnames"
import disableCarouselAriaHidden from "../../utils/disableCarouselAriaHidden"
import { getImageUrl } from "../../utils/imageProxyUrl"

export type CarouselProps = {
  title: string
  productCardCarousel: string[]
  slidesToShow: number
  selectedImageUrl: string
  productTagImage?: string
}

const galleryCarouselId = "galleryCarousel"

export default function GalleryCarousel({
  title,
  selectedImageUrl,
  productCardCarousel,
  slidesToShow,
  productTagImage,
}: CarouselProps) {
  const [imageSrcUrl, setImageSrcUrl] = useState(selectedImageUrl)
  const getImageSrcUrl = (imageUrl: string) => () => {
    setImageSrcUrl(imageUrl)
  }

  const beforeChangeSlide = (prev: number, next: number) => {
    if (prev !== next && productCardCarousel.length > slidesToShow) {
      setImageSrcUrl(productCardCarousel[next])
    }
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    autoplaySpeed: 5000,
    prevArrow: <ChevronLeftIcon />,
    nextArrow: <ChevronRightIcon />,
    draggable: true,
    focusOnSelect: true,
    className: "GalleryCarousel",
    beforeChange: beforeChangeSlide,
    afterChange: () => {
      disableCarouselAriaHidden(galleryCarouselId)
    },
  }
  useEffect(() => {
    getImageSrcUrl(selectedImageUrl)()
  }, [selectedImageUrl])

  useEffect(() => {
    disableCarouselAriaHidden(galleryCarouselId)
  }, [])

  return (
    <>
      <ProductImage
        productTagImage={productTagImage}
        imageSrc={imageSrcUrl || selectedImageUrl}
      />
      <div
        className={classNames("slide-image-section", {
          "slide-image-section--more": productCardCarousel.length > 1,
        })}
      >
        <Slider {...settings}>
          {productCardCarousel.map((productImage, index) => (
            <div
              className="card"
              key={index}
              role="button"
              tabIndex={0}
              onClick={getImageSrcUrl(productImage)}
            >
              <div className="img-container">
                <img src={getImageUrl(productImage, 150)} alt={title} />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  )
}
