export const getImageUrl = (productImage: string, size = 150) => {
  const IMAGE_PROXY = window.ENV["IMG_URL"]
  const fallbackImage = "/images/missing_medium.png"
  // Check if the product image is missing, use the fallback if so
  if (productImage.includes(fallbackImage)) {
    return productImage
  }

  // Return the proxy URL with the size and image
  return `https://${IMAGE_PROXY}/${size},fit/${productImage}`
}
