import { useEffect, useState } from "react"
import { SvgIconComponent } from "@mui/icons-material"
import ZoomInIcon from "@mui/icons-material/ZoomIn"
import ZoomOutIcon from "@mui/icons-material/ZoomOut"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"
import { getProductStyles } from "../store/product-card/style/ProductStyle"
import Box from "@mui/material/Box"
import { styled, useMediaQuery, useTheme } from "@mui/material"
import Theme from "../../theme/mui-theme"
import { getImageUrl } from "../../utils/imageProxyUrl"

export type ProductImageProps = {
  imageSrc: string
  productTagImage?: string
}
function ProductImage({ imageSrc, productTagImage }: ProductImageProps) {
  const [isZoom, setIsZoom] = useState(false)
  const [isPanning, setIsPanning] = useState(false)
  const [imgChanged, setImgChanged] = useState(false)
  const styles = getProductStyles(Theme)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const imageZoomActions = (event, centerView, resetTransform) => {
    if (!isPanning) {
      if (isZoom) {
        setIsZoom(false)
        resetTransform(300)
      } else {
        setIsZoom(true)
        centerView("2.7", 300)
      }
    }
  }

  const resetClickAction = () => {
    clearTimeout(window.resetClickActionTimer)
    window.resetClickActionTimer = setTimeout(() => {
      setIsPanning(false)
    }, 300)
  }
  useEffect(() => {
    setImgChanged(true)
  }, [imageSrc])

  return (
    <>
      <div className="product-image">
        {productTagImage ? (
          <Box
            component="div"
            sx={{
              ...styles.flashDealTagBase,
              ...styles.flashDealTagResponsive,
            }}
            style={{ left: "84%" }}
          >
            <Box
              component="img"
              src={productTagImage}
              alt={productTagImage}
              sx={styles.flashDealTagImg}
            />
          </Box>
        ) : (
          ""
        )}
        {!isMobile ? (
          <TransformWrapper
            initialScale={1}
            wheel={{ disabled: true }}
            pinch={{ step: 1 }}
            doubleClick={{ disabled: true }}
            maxScale={8}
            onPanning={() => {
              setIsPanning(true)
            }}
            onPanningStop={() => {
              resetClickAction()
            }}
          >
            {({ centerView, resetTransform }) => (
              <>
                <div className="tools">
                  {isZoom ? (
                    <StyledZoomOutIcon
                      onClick={() => {
                        setIsZoom(false)
                        resetTransform(300)
                      }}
                      style={{ overflow: "visible" }}
                    />
                  ) : (
                    <StyledZoomInIcon
                      onClick={() => {
                        setIsZoom(true)
                        centerView("2.7", 300)
                      }}
                    />
                  )}
                </div>
                {imgChanged && resetTransform()}
                {imgChanged && setImgChanged(false)}
                {imgChanged && setIsZoom(false)}
                <div>
                  <TransformComponent>
                    <div
                      className="image-container"
                      onClick={() => {
                        imageZoomActions(event, centerView, resetTransform)
                      }}
                      style={{ overflow: "visible" }}
                    >
                      <img
                        src={getImageUrl(imageSrc, 386.5)}
                        alt="product-image"
                        className={!isZoom ? "zoom-out-image " : ""}
                      />
                    </div>
                  </TransformComponent>
                </div>
              </>
            )}
          </TransformWrapper>
        ) : (
          <img src={getImageUrl(imageSrc, 300)} alt="product-image" />
        )}
      </div>
    </>
  )
}

export default ProductImage

const generateStyledIcon = (IconComponent: SvgIconComponent) => styled(
  IconComponent
)`
  position: absolute;
  z-index: 9;
  bottom: 2%;
  right: 1.5%;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.main};
`

const StyledZoomInIcon = generateStyledIcon(ZoomInIcon)
const StyledZoomOutIcon = generateStyledIcon(ZoomOutIcon)
